
* {
  margin: auto;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  font-weight:400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
body {
  display:flex;
  background-color: #192231 !important;
  color: #e9e7da !important
}
.experience {
  margin:10%;
  scroll-margin-top: 20px;
}

.home {
  display:flex;
  flex-direction: row;
margin:10%;
}
#home{
  scroll-margin-top: 100px !important;
}
#projects{
  scroll-margin-top: 100px;
}
#experience{
  scroll-margin-top: 100px;
}
.home a {
  margin: '5px' !important;
  background-color: #AA6373 !important;
  border: 'none' ;
}
.home a:hover {
  background-color: #823f4f !important;
}
.button {
  margin: '5px' !important;
  background-color: #AA6373 !important;
  border: 'none' ;
}
.button:hover {
  background-color: #823f4f !important;
}

.photo{
  width:300px;
  border-radius: 50%;
  border:3px solid #985e6d;
  height: 300px;
  background-size: 300px 300px;
  background-image: url('../public/picpic.jpg');
  transition: 500ms;
  overflow: hidden;
}
.photo img {
  max-width: 100%;
  max-height: 100%;
}
.socials img {
  padding:5px;
  width: 40px
}
.nav-link:hover{
  text-decoration: overline !important;
}
.card {
margin:auto !important;
display:flex;
flex-direction: row;
flex-wrap:wrap;
background-color: #192231 !important;
margin-top:10px !important;
border:none !important;
border-top:3px solid #985e6d !important;
border-bottom: 3px solid #985e6d !important;
}
.item {
  background:none !important;
  border: none !important;
  text-decoration: underline !important;
  text-decoration-color:rgb(191, 191, 191) !important;
  color:white !important
}


.card-title {
  font-family: 'Quicksand', sans-serif;
  font-weight:600 !important;
}

.card-link {
  text-decoration: none;
  color:white
}
.navbar{
position:sticky!important;
width:100% !important;
top:0 !important;
left:0 !important;
right:0 !important;
background-color: #98878f !important;
background-image: linear-gradient(to right, #494e6b, #985e6d);
z-index: 999;
}


.techies .card {
  margin-left: 10px !important;
}
.navbar a {
  color: rgb(255, 255, 255) !important;

}

.home .card {
  border:none;
  border-top:3px solid #985e6d !important;
  margin-left: 20px !important;
}

.list {
  display:flex;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.techs img {
  width: 30px;
  height:30px;
}
.techs {
  padding:10px;
  margin:10px
}

.line  {
  border-bottom: 2px solid #985e6d !important;
  width: 90%;
  margin:10px
}
.proj {
  display:flex;
  background-color: #494e6b !important;
  color:white  !important;
  justify-content: center !important;
  border-radius: 25px;
}
.projects h1{
margin:5%
}
.projects a {
  background-color: #985e6d;
  border:none;
  box-shadow: 1px 1px 1px black;
}
.projects a:hover {
  background-color: #823f4f;
  box-shadow: 1px 1px 1px #192231;
}
.projects button {
  background-color: #985e6d;
  border:none;
  box-shadow: 1px 1px 1px #192231;
}
.projects button:hover {
  background-color: #823f4f;
  box-shadow: 1px 1px 1px #192231;
}
.projects button:focus {
  background-color: #823f4f;
  box-shadow: 1px 1px 1px #192231;
  outline: none !important;
}
.contact {
  margin:10%
}
.mySwiper {
  width: 700px;
  height:400px;

}
.swiper-button-next {
   color: white !important;
  opacity: 20% !important;
  }
  
.swiper-button-prev {
   color: white !important;
  opacity: 20% !important;
  }
  .proj a {
    margin:5px
  }
  .fade-in-section {
    margin:0;
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    margin:0;
  }

@media screen and (min-width: 640px) {
  .mySwiper {
    width: 600px;
    height:400px
  }
}

@media screen and (min-width: 768px) {
  .mySwiper {
    width: 700px;
    height:400px
  }
}

@media screen and (max-width: 575px) {
  .home {
    flex-direction: column;
  }
.mySwiper {
  width: 300px
}
.swiper-button-next{
  display:none !important
}
.swiper-button-prev{
  display:none !important
}
}
